import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { PlatformService } from './platform.service';
import { ReplaySubject } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import { CmsApiService } from './api/cms-api.service';
import {SsApiService} from './api/ss-api.service';
import {Location} from '@angular/common';
import {CookieService} from 'ngx-unificator/services';

export const PTAG_STRING = 'ptag';
export const PARTNER_ID_STRING = 'id_id';
export const STAG_STRING = 'stag';

@Injectable({
  providedIn: 'root',
})
export class StagDetectorService {

  public onDetectStag$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private _cookie: CookieService,
    private _router: Router,
    private _serializer: UrlSerializer,
    private _platform: PlatformService,
    private _cmsApi: CmsApiService,
    private _ssApi: SsApiService,
    private _storage: LocalstorageService,
    private _location: Location
  ) {

  }
  /**
   * Detect query parameters
   * @private
   */
  public detectQueryParameters() {
    if (!this._platform.isBrowser) {
      return;
    }
    const params = this._serializer.parse(window.location.search).queryParams;
    const domain = location.hostname.split('.').slice(-2).join('.');
    setTimeout(() => {
      if (params.stag || params.test) {
        if (params.stag) {
          this._detectStag(params, domain);
        }

        if (params.test) {
          this._detectTestParameter(params);
        }
        const tree = this._router.parseUrl(
          this._location.normalize(window.location.pathname)
        );
        tree.queryParams = params;
        this._router.navigateByUrl(tree);
      }
    }, 100);
  }

  /**
   * Save stag to cookie if exists
   *
   * @private
   */
  private _detectStag(params: any, domain: string) {
    const nocache = params.nocache;
    if (params.stag || nocache) {

      this._detectAnCutStag(params, domain);

      this._ssApi.infoLocales({
        stag: params.stag
      }).subscribe();

      delete params.stag;

    } else if (!params.stag && this._cookie.check(PTAG_STRING)) {
      this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING).split('_')[0],  999, '/', domain);
    }
    if (nocache) {
      delete params.nocache;
    }
  }

  private _detectTestParameter(params: any) {
    if (params.hasOwnProperty('test')) {
      this._storage.set('test', 'true');
      delete params.test;
    }
  }

  /**
   * Detect ptag and set and cut partner id stag for Back-End
   * @param params
   * @param domain
   * @private
   */
  private _detectAnCutStag(params, domain) {
    switch (true) {
      case this._cookie.check(PTAG_STRING) && this._cookie.check(PARTNER_ID_STRING):
        break;
      case this._cookie.check(PTAG_STRING):
        this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING).split('_')[0], 999, '/', domain);
        break;
      default:
        this._cookie.set(PARTNER_ID_STRING, params.stag.split('_')[0], 999, '/', domain);
        this._cookie.set(PTAG_STRING, params.stag, 999, '/', domain);
        this._storage.set(PARTNER_ID_STRING, params.stag.split('_')[0]);
        this._storage.set(STAG_STRING, params.stag);
    }
  }
}
